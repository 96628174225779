export function exportSalesToCSV(groupedSales) {
    const rows = [
      [
        "CustomerId",
        "UserId",
        "CompanyName",
        "Seller",
        "Zone",
        "AreaSqm",
        "Status",
        "FinalStatus",
        "Followup",
        "Category",
        "Description",
        "CreatedAt (EN)",
        "NextSteps"
      ]
    ];
  
    Object.entries(groupedSales).forEach(([customerId, entries]) => {
      entries.forEach((entry) => {
        const rawDate = entry.CreatedAt;
        let formattedDate = "-";
  
        if (rawDate) {
          if (typeof rawDate === "string" || rawDate instanceof String) {
            const d = new Date(rawDate);
            if (!isNaN(d.getTime())) formattedDate = d.toLocaleString("en-US");
          } else if (typeof rawDate === "number") {
            const d = new Date(rawDate * 1000);
            if (!isNaN(d.getTime())) formattedDate = d.toLocaleString("en-US");
          }
        }
  
        rows.push([
          customerId,
          entry.UserId || "-",
          entry.CompanyName || "-",
          entry.SellerName || "-",
          entry.ZoneOffered || "-",
          entry.AreaOfferedSqm || "-",
          entry.Status || "-",
          entry.FinalStatus || "-",
          entry.StatusFollowup || "-",
          entry.category || "-",
          (entry.description || "-").replace(/\n/g, " "),
          formattedDate,
          entry.next_steps || "-"
        ]);
      });
    });
  
    const csvContent = rows
      .map((row) =>
        row.map((value) => `"${String(value).replace(/"/g, '""')}"`).join(",")
      )
      .join("\n");
  
    const blob = new Blob(["\uFEFF" + csvContent], {
      type: "text/csv;charset=utf-8;",
    });
  
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "sales_report.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  