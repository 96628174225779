import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./LoginScreen.css";

const LoginScreen = ({ onLogin }) => {
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    await onLogin(inputPassword);
    setLoading(false);
  };

  return (
    <div className="login-screen">
      <img src="/logo512.png" alt="NavisX Logo" className="login-logo" />
      <h2>🔒 NavisX</h2>
      <p>
        Please enter your access password to continue. <br />
        กรุณากรอกรหัสผ่านเพื่อเข้าใช้งาน
      </p>

      <div className="password-input-container">
        <input
            type={showPassword ? "text" : "password"}
            placeholder="Enter password / กรอกรหัสผ่าน"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            disabled={loading}
        />
        <button
            type="button"
            className="eye-button"
            onClick={() => setShowPassword(!showPassword)}
            aria-label={showPassword ? "Hide password" : "Show password"}
            title={showPassword ? "Hide password" : "Show password"}
        >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
        </button>
        </div>



      <button onClick={handleLogin} disabled={loading}>
        {loading ? "Checking..." : "Login"}
      </button>

      {loading && (
        <div className="login-loader">
          <div className="dot-flashing"></div>
          <p>Checking password... / กำลังตรวจสอบรหัสผ่าน...</p>
        </div>
      )}
    </div>
  );
};

export default LoginScreen;
