import React, { useState } from "react";
import SalesReport from "./components/SalesReport";
import LoginScreen from "./components/LoginScreen";
import InvalidPassword from "./components/InvalidPassword";

function App() {
  const [password, setPassword] = useState(null);
  const [loginError, setLoginError] = useState(false);

  const handleLogin = async (enteredPassword) => {
    try {
      console.log("🔐 Attempting login with:", enteredPassword);

      const response = await fetch(
        "https://i86yazigq2.execute-api.us-west-2.amazonaws.com/prod/report",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password: enteredPassword }),
        }
      );

      const text = await response.text();
      console.log("📡 Response status:", response.status);
      console.log("📦 Response body:", text);

      if (response.status === 403) {
        console.warn("❌ Invalid password.");
        setLoginError(true);
        return;
      }

      if (!response.ok) {
        console.error("🚨 Server error", response.status);
        setLoginError(true);
        return;
      }

      setPassword(enteredPassword);
    } catch (err) {
      console.error("❌ Login failed:", err);
      setLoginError(true);
    }
  };

  const isLoggedIn = !!password && !loginError;

  return (
    <div className={isLoggedIn ? "app-logged-in" : "app-login"}>
      {loginError ? (
        <InvalidPassword onRetry={() => setLoginError(false)} />
      ) : !password ? (
        <LoginScreen onLogin={handleLogin} />
      ) : (
        <SalesReport password={password} />
      )}
    </div>
  );
}

export default App;
