import React from "react";
import "./InvalidPassword.css";

const InvalidPassword = ({ onRetry }) => (
  <div className="invalid-password">
    <h2>🚫 Invalid Password</h2>
    <p>
      Sorry, the password you entered is incorrect. <br />
      กรุณาตรวจสอบรหัสผ่านของคุณอีกครั้ง
    </p>
    <button onClick={onRetry}>🔁 Try Again / ลองอีกครั้ง</button>
  </div>
);

export default InvalidPassword;
