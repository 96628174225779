import React, { useEffect, useState, useCallback } from "react";
import "./SalesReport.css";
import { exportSalesToCSV } from "../utils/exportToCsv";

const SalesReport = ({ password }) => {
  const [groupedSales, setGroupedSales] = useState({});
  const [expandedCustomers, setExpandedCustomers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // groups per page

  const downloadCSV = () => {
    exportSalesToCSV(groupedSales);
  };


  const fetchReport = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://i86yazigq2.execute-api.us-west-2.amazonaws.com/prod/report",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );
  
      if (response.status === 403) {
        console.error("Unauthorized access: Invalid password");
        return;
      }
  
      const data = await response.json();
      const grouped = {};
  
      (data.sales || []).forEach((item) => {
        const key = item.CustomerId || "Unknown";
        if (!grouped[key]) grouped[key] = [];
        grouped[key].push(item);
      });
  
      setGroupedSales(grouped);
      setCurrentPage(1);
    } catch (error) {
      console.error("Failed to fetch report:", error);
    } finally {
      setLoading(false);
    }
  }, [password]);
  

  useEffect(() => {
    if (password) {
      fetchReport();
    }
  }, [password, fetchReport]); // ✅ include fetchReport
  


  const toggleCustomer = (customerId) => {
    setExpandedCustomers((prev) => ({
      ...prev,
      [customerId]: !prev[customerId],
    }));
  };

  // Convert groupedSales to an array for filtering and pagination
  const groupsArray = Object.entries(groupedSales);

  // Filter groups based on search term: check if any field in the first record matches the search text
  const filteredGroups = searchTerm
    ? groupsArray.filter(([customerId, entries]) => {
        const record = entries[0];
        // Join all values from the record into one string and check if it includes the search term
        return Object.values(record)
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    : groupsArray;

  const totalPages = Math.ceil(filteredGroups.length / itemsPerPage);
  const currentGroups = filteredGroups.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const goToPage = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  return (
    <div className="container">
      {/* Top header with logo and company name */}
      <div className="header">
        <div className="logo">
          <img src="/logo512.png" alt="NavisX Logo" />
        </div>
        <h1>NavisX</h1>
      </div>

      {/* Subheader with report title, refresh button, and search bar */}
      <div className="subheader">
        <h2>รายงานกิจกรรมการขาย</h2>
        <div className="subheader-actions">
            <input
            type="text"
            placeholder="ค้นหา..."
            value={searchTerm}
            onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
            }}
            />
            <button
            onClick={fetchReport}
            disabled={loading}
            className="primary-button refresh-button"
            >
            🔄 <span className="refresh-label">{loading ? "กำลังรีเฟรช..." : "รีเฟรชรายงาน"}</span>
            </button>
        </div>
        </div>


      {/* Main content */}
      <div className="content">
        <div className="table-container">
          {loading && <div className="loading-overlay">กำลังรีเฟรช...</div>}
          <table className="data-table">
            <thead>
              <tr>
                <th>Activities / กิจกรรม</th>
                <th>Customer / ลูกค้า</th>
                <th>Seller / ผู้ขาย</th>
                <th>Company</th>
                <th>Zone / โซน</th>
                <th>Area (sqm) / พื้นที่ (ตร.ม.)</th>
                <th>Status / สถานะ</th>
                <th>Final Status / สถานะสุดท้าย</th>
                <th>Follow-up / ติดตาม</th>
              </tr>
            </thead>
            <tbody>
              {currentGroups.map(([customerId, entries]) => {
                const {
                  SellerName,
                  CompanyName,
                  ZoneOffered,
                  AreaOfferedSqm,
                  Status,
                  FinalStatus,
                  StatusFollowup,
                } = entries[0];
                const isExpanded = expandedCustomers[customerId];

                return (
                  <React.Fragment key={customerId}>
                    <tr className="group-row">
                      <td>
                        <button className="toggle-button" onClick={() => toggleCustomer(customerId)}>
                          {isExpanded ? "➖ Hide" : "➕ Show"}
                        </button>
                      </td>
                      <td>{customerId}</td>
                      <td>{SellerName || "-"}</td>
                      <td>{CompanyName || "-"}</td>
                      <td>{ZoneOffered || "-"}</td>
                      <td className="numeric">{AreaOfferedSqm || "-"}</td>
                      <td>{Status || "-"}</td>
                      <td>{FinalStatus || "-"}</td>
                      <td className="follow-up">{StatusFollowup || "-"}</td>
                    </tr>

                    {isExpanded &&
                      entries.map((entry, subIdx) => (
                        <tr key={subIdx} className="detail-row">
                          <td colSpan="9">
                            <strong>{entry.Status}</strong>: {entry.description}
                            <br />
                            <em>
                              🗓 {new Date(entry.CreatedAt * 1000).toLocaleString("th-TH", { calendar: "buddhist" })} /{" "}
                              {new Date(entry.CreatedAt * 1000).toLocaleString("en-US")}
                            </em>
                            <br />
                            <strong>Next steps:</strong> {entry.next_steps || "-"}
                          </td>
                        </tr>
                      ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="pagination">
          <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>

        <div className="actions right">
          <button onClick={downloadCSV} className="primary-button">
            📥 ดาวน์โหลดไฟล์ .csv
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalesReport;
